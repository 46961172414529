footer {
    background: var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    font-size: .9rem;
    margin-top: 7rem;
}

footer a {
    color: var(--color-bg);
}

.footer__logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}

/*MEDIA QUERIE (SML. DEVICES)*/
@media screen  and (max-width: 600px) {
    footer {
        margin-top: 25rem;
    }
}